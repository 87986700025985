$heading-font: 'Teko', sans-serif;
$body-font: 'Rubik', sans-serif;

h1, h2, h3, h4, h5, h6, .menu  {
	font-family: $heading-font;
	line-height: 1;
	font-weight: 700;
}
body {
	font-family: $body-font;
    margin:0; padding:0;
}

//HEADER
header {
    padding:24px;
}

//MAP

.mapboxgl-popup {
    z-index: 10000;
}

 .mapboxgl-popup-content {
    padding:30px!important;
    width:210px;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    
     h6 {
         margin:0;
         padding-top:12px;
     }
}

.mapboxgl-popup-close-button {
    padding:5px;
    font-size:16px;
    font-weight: 700;;
}

#map { 
    position:absolute; top:125px; bottom:0; width:100%; 
}

//NAVIGATION

.menu {
	text-transform: uppercase;
	font-size: 7vh;
}
.menu-button {
	display: inline-block;
	padding: .5em;
	background-color: none;
	border: none;
}
.burger-icon {
	@include burger(35px, 2px, 8px, #444);
}
.menu-button.is-active .burger-icon {
	@include burger-to-cross;
}
#nav {
	height:65vh;
    background:#414143;
    z-index: 9000;
}

#social {
    margin-top:48px;
}

//THUMBNAILS

figure img {
    width:100%;
    height: auto;
}

figure.figurefx {
  //width: 600px;
  //margin: 30px auto;
	padding: 0;
	display: block;
	position: relative;
	overflow: hidden; /* hide overflowing elements by default */
    margin:5px;
}

figure.figurefx figcaption {
	position: absolute;
	display: block;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	text-align: center;
	background: white;
	padding: 15px;
	z-index: 100;
	width: 100%;
	max-height: 100%;
	overflow: hidden;
	top: 50%;
	left: 0;
	-moz-transform: translate3d(-100%, -50%, 0); /* position caption outside layout horizontally and centered vertically */
	-webkit-transform: translate3d(-100%, -50%, 0);
	transform: translate3d(-100%, -50%, 0);
	opacity: 0;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}

figure.figurefx figcaption a {
	text-decoration: none;
}

/*** Push Up effect ****/
figure.pushup img {
	-moz-transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}

figure.pushup figcaption {
	top: 100%;
	opacity: 1;
	-moz-transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}


figure.pushup:hover img {
	-moz-transform: translate3d(0, -20px, 0); /* move image up vertically a bit for parallax effect */
	-webkit-transform: translate3d(0, -20px, 0);
	transform: translate3d(0, -20px, 0);
  	opacity: .75;
	-moz-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
 
}

figure.pushup:hover figcaption{
	-moz-transform: translate3d(0, -100%, 0); /* move caption up vertically to fully reveal caption */
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0);
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}

//CONTENT
section {
	padding:24px 0 ;
	@include breakpoint(large) { padding:100px 0;}
}
#content {
	p {
		margin-bottom: 3rem;
	}
}

#tags {
	text-transform: uppercase;
    background:#cb3840;
    a { 
        color:#fff;
        padding:5px;
    }
    a:hover {
        color:#414143;
    }
}

// DETAIL

#detail {
	
	h2 {
		font-size: 36px;
		margin-top: 24px;
        line-height: .9;
		@include breakpoint(medium) {
			font-size:48px;
			margin:0;
		}
	}
	h3 {
		font-size: 21px;
		color:$dark-gray;
		font-weight: 400;
		text-transform: uppercase;
		margin: 0;
	}
	
	h2, h4 {
		margin-bottom: 18px;
	}
	
	p {
		font-size: 14px;
		@include breakpoint(medium) {
			font-size: 16px;
		}
	}
	
	#tags {
        background:none;
		a { 
			background: $dark-gray;
			color:white; 
			font-size:12px;
			padding:10px;
			margin:4px;
			display: inline-block;
		}
	}
}

figcaption {
    font-size: 11px;
    text-align: center;
    padding:5px 0;
}

//FOOTER-LIST

#list {
	
	li {
		list-style: none;
		font-size: 11px;
		line-height: 1.1;
		padding-bottom: 8px;
	}
	
	li a {
		color:$dark-gray;
	}
	
	h6 { text-transform: uppercase; }
	
}

//FOOTER 

footer {
    padding:36px 0;
    font-size: 11px;
}




